&.balls {
	width: 4em;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;

	div {
		width: 0.8em;
		height: 0.8em;
		border-radius: 50%;
		background-color: #fc2f70;

		&:nth-of-type(1) {
			transform: translateX(-100%);
			animation: left-swing 0.5s ease-in alternate infinite;
		}

		&:nth-of-type(3) {
			transform: translateX(-95%);
			animation: right-swing 0.5s ease-out alternate infinite;
		}
	}
}
