div {
	&.fx-loader {
		/*
		<div class="fx-loader grow-bars">
		  <span></span>
		  <span></span>
		  <span></span>
		  <span></span>
		</div>
		*/
		@import "loader/grow-bars"; /**/

		/*
		<div class="fx-loader box-fill-rotate"></div>
		*/
		@import "loader/fill-box-rotate"; /**/

		/*
		<div class="fx-loader circle-rotate">
		  <div class="coin"></div>
		</div>
		*/
		@import "loader/circle-rotate"; /**/

		/*
		<div class="fx-loader balls">
		  <div></div>
		  <div></div>
		  <div></div>
		</div>
		*/
		@import "loader/balls"; /**/

		/*
		<div class="fx-loader spin-stretch"></div>
		*/
		@import "loader/spin-stretch"; /**/

		/*
		<div class="fx-loader dots">
		  <div></div>
		  <div></div>
		  <div></div>
		</div>
		*/
		@import "loader/dots"; /**/

		/*
		<div class="fx-loader flip">
		  <div class="plane"></div>
		</div>
		*/
		&.flip {
			perspective: 120px;

			@import "loader/flip";
		}

		/*
		<div class="fx-loader matrix-dot">
		  <div></div>
		  <div></div>
		  <div></div>
		  <div></div>
		  <div></div>
		  <div></div>
		  <div></div>
		  <div></div>
		  <div></div>
		</div>
		*/
		&.matrix-dot {
			width: 3em;
			height: 3em;
			display: grid;
			grid-template-rows: repeat(3, 1fr);
			grid-template-columns: repeat(3, 1fr);
			justify-items: center;
			align-items: center;

			@import "loader/matrix-dot";
		}

		/*
		<div class="fx-loader multiline-loader">
		  <div class="outer"></div>
		  <div class="middle"></div>
		  <div class="inner"></div>
		</div>
		*/
		&.multiline-loader {
			position: relative;

			@import "loader/multiline-loader";
		}

		/*
		<div class="fx-loader pulse-spin"></div>
		*/
		&.pulse-spin {
			display: flex;
			width: 3.5em;
			height: 3.5em;
			border: 3px solid transparent;
			border-top-color: #3cefff;
			border-bottom-color: #3cefff;
			border-radius: 50%;
			animation: multiline-spin 1.5s linear infinite;

			@import "loader/pulse-spin";
		}

		/*
		<div class="fx-loader circle-dot">
			<svg viewBox="0 0 50 50">
			  <circle class="ring" cx="25" cy="25" r="20"></circle>
			  <circle class="ball" cx="25" cy="5" r="3.5"></circle>
			</svg>
		</div>
		*/
		&.circle-dot {
			@import "loader/circle-dot";
		}

		/*
		<div class="fx-loader circle-stretch">
			<svg viewBox="25 25 50 50">
			  <circle cx="50" cy="50" r="20"></circle>
			</svg>
		</div>
		*/
		&.circle-stretch {
			@import "loader/circle-stretch";
		}

		/*
		<div class="fx-loader circle-rotate-line"></div>
		*/
		&.circle-rotate-line {
			@import "loader/circle-rotate-line";
		}

		/*
		<div class="fx-loader balls-wave">
			<div class="balls">
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
		*/
		&.balls-wave {
			@import "loader/balls-wave";
		}
	}
}