.outer,
.middle,
.inner {
	border: 3px solid transparent;
	border-top-color: #3cefff;
	border-right-color: #3cefff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
}

.outer {
	width: 3.5em;
	height: 3.5em;
	margin-left: -1.75em;
	margin-top: -1.75em;
	animation: multiline-spin 2s linear infinite;
}

.middle {
	width: 2.1em;
	height: 2.1em;
	margin-left: -1.05em;
	margin-top: -1.05em;
	animation: multiline-spin 1.75s linear reverse infinite;
}

.inner {
	width: 0.8em;
	height: 0.8em;
	margin-left: -0.4em;
	margin-top: -0.4em;
	animation: multiline-spin 1.5s linear infinite;
}