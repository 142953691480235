@import url('https://fonts.googleapis.com/css?family=Shojumaru&display=swap&subset=latin-ext');
@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "mixins";
@import "bootstrap";
@import "style";
@import "navbar";
@import "cssfx/cssfx";
@import "cssfx/dropdown";
@import "bugreport";
@import "gallery";
@import "chat";
@import "calendar";

#loader {
    display: none !important;
    top: -30000px;
}

body, html {
    position: relative;
    font-size: 10px;
    min-height: 100vh;

    .content-wrap {
        padding-bottom: 15rem; /* Footer height */
    }

    .background {
        background-color: rgba($c-bg, 0.9);
    }

    &#landingpage {
        #loader {
            display: none;
        }
    }

    &#about-us,
    &#login,
    &#rules,
    &#bugreport,
    &#privacy-police,
    &#member,
    &#calendar,
    &.dashboard,
    &#become-a-member {
        #teaser-carousel {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        .content-wrap {
            position: relative;
            padding-top: 100vh;
            z-index: 100;
        }
    }

    &#rules,
    &#bugreport,
    &#privacy-police {
        .content-wrap {
            padding-top: 20vh;
        }
    }

    &#about-us {
        .content-wrap {
            padding-top: 100vh;
        }
    }

    &#login,
    &#member,
    &#calendar,
    &.dashboard,
    &#become-a-member {
        .content-wrap {
            padding-top: 15rem;
        }
    }

    > * {
        font-size: 1.4rem;
    }
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 12.5rem;
    z-index: 10;

    #login &,
    .dashboard &,
    #bugreport &,
    #become-a-member & {
        position: fixed;
    }
}