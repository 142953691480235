/* Jelly Button */
&.fx-jelly {
	z-index: 1;
	font-size: inherit;
	font-family: inherit;
	color: white;
	padding: 0.5em 1em;
	outline: none;
	border: none;
	background-color: hsl(236, 32%, 26%);

	&:hover {
		cursor: pointer;
		animation: jelly 0.5s;
	}
}