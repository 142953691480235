.navbar {
    a {
        font-size: 1.6rem;
    }

    height: 105px;
    transition: 0.4s ease-in-out;

    .nav-item {
        position: relative;
    }

    .badge {
        position: absolute;
        top: -0.6rem;
        right: 0;
        border-radius: 3rem;
    }

    .dropdown-toggle {
        padding-right: 2rem !important;

        &::after {
            position: absolute;
            top: 50%;
            right: 0.5rem;
            transform: translateY(-50%);
        }

        .badge {
            right: 1.8rem;
        }

        #navbarDropdownMessages {
            > svg {
                font-size: 2rem;
            }
        }

        .profile-logo {
        }
    }

    .nav-bubble {
        position: relative;
        display: inline-block;
        background-color: $c-white;
        width: 4rem;
        height: 4rem;
        border-radius: 4rem;
        overflow: hidden;

        > * {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .navbar-brand {
        img {
            height: 100px;
            width: auto;
            transition: 0.4s ease-in-out;
        }
    }

    .navbar-nav {
        .nav-link {
            font-size: 1.6rem;
            transition: 0.4s ease-in-out;
        }
    }

    &.small {
        height: 60px;
        background-color: rgba($c-white, 0.8);
        transition: 0.4s ease-in-out;

        .navbar-brand {
            img {
                height: 50px;
                width: auto;
                transition: 0.4s ease-in-out;
            }
        }

        .navbar-nav {
            .nav-link {
                font-size: 1.2rem;
                transition: 0.4s ease-in-out;
            }
        }

        .nav-bubble {
            width: 3rem;
            height: 3rem;
        }

        //&.small {
        //	animation: animate-navbar 1s 1 forwards;
        //	-webkit-animation: animate-navbar 1s 1 forwards;
        //}
        //
        //&.big {
        //	animation: animate-navbar 1s 1 backwards;
        //	-webkit-animation: animate-navbar 1s 1 backwards;
        //}
    }

    @include media-breakpoint-down(md) {
        .navbar-brand,
        .navbar-toggler {
            z-index: 999;
        }

        .navbar-collapse {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding-top: 10rem;
            background: $c-hsrmt-blue;

            > ul {
                > li {
                    > a {
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }
                }
            }

            .fx-bubble {
                &.in-to-out {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

.navbar-nav .dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu > .dropdown-menu {
    top: 0;
    /*left:-100%;*/
    left: -16rem; /* 10rem is the min-width of dropdown-menu */
    margin-top: -6px;
}



