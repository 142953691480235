&.fx-slide {
	z-index: 1;
	position: relative;
	font-size: inherit;
	font-family: inherit;
	color: white;
	padding: 0.5em 1em;
	outline: none;
	border: none;
	background-color: hsl(236, 32%, 26%);

	&::before {
		content: '';
		z-index: -1;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #fc2f70;
		transition: transform 0.25s ease-in-out;
	}

	&:hover {
		cursor: pointer;
	}

	&.ttb {
		&::before {
			transform-origin: center bottom;
			transform: scaleY(0);
		}

		&:hover {
			&::before {
				transform-origin: center top;
				transform: scaleY(1);
			}
		}
	}

	&.btt {
		&::before {
			transform-origin: center top;
			transform: scaleY(0);
		}

		&:hover {
			&::before {
				transform-origin: center bottom;
				transform: scaleY(1);
			}
		}
	}

	&.ltr {
		&::before {
			transform-origin: center right;
			transform: scaleX(0);
		}

		&:hover {
			&::before {
				transform-origin: center left;
				transform: scaleX(1);
			}
		}
	}

	&.rtl {
		&::before {
			transform-origin: center left;
			transform: scaleX(0);
		}

		&:hover {
			&::before {
				transform-origin: center right;
				transform: scaleX(1);
			}
		}
	}
}