&.dots {
	width: 3.5em;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;

	div {
		width: 0.8em;
		height: 0.8em;
		border-radius: 50%;
		background-color: #fc2f70;
		animation: fade 0.8s ease-in-out alternate infinite;

		&:nth-of-type(1) {
			animation-delay: -0.4s;
		}

		&:nth-of-type(2) {
			animation-delay: -0.2s;
		}
	}
}