/* Profile sidebar */
.profile-userpic {
	.profile-userpic-border {
		position: relative;
		float: none;
		margin: 0 auto;
		width: 50%;
		height: 0;
		padding-bottom: 50%;
		display: block;
		-webkit-border-radius: 50% !important;
		-moz-border-radius: 50% !important;
		border-radius: 50% !important;
		overflow: hidden;

		.edit_avatar,
		.remove_avatar {
			cursor: pointer;
			opacity: 0;
			position: absolute;
			left: 0;
			right: 0;
			height: 50%;
			text-align: center;
			transition: 0.4s ease-in-out;

			a {
				color: $c-text-invert;
				display: block;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}

			&:hover {
				opacity: 1;
				transition: 0.4s ease-in-out;
			}

			@include media-breakpoint-down(md) {
				opacity: 1;
			}
		}

		.remove_avatar {
			background: rgba(255, 0, 0, 0.3);
			bottom: 0;
		}

		.edit_avatar {
			background: rgba(0, 255, 0, 0.3);
			top: 0;
		}

		img {
			width: 100%;
			height: auto;
		}
	}
}

.profile-usertitle {
	text-align: center;
	margin-top: 20px;
}

.profile-usertitle-name {
	color: #5a7391;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 7px;
	.mellegage & {
		color: $c-hsrmt-red;
	}
}

.profile-usertitle-job {
	text-transform: uppercase;
	color: #5b9bd1;
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 15px;
	.mellegage & {
		color: $c-hsrmt-red;
	}
}

.profile-userbuttons {
	text-align: center;
	margin-top: 10px;

	.btn {
		text-transform: uppercase;
		font-size: 11px;
		font-weight: 600;
		padding: 6px 15px;
		margin-right: 5px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.profile-usermenu {
	margin-top: 30px;

	ul {
		li {
			display: block;
			width: 100%;
			border-bottom: 1px solid #f0f4f7;

			&:last-child {
				border-bottom: none;
			}

			&.active {
				border-bottom: none;

				a {
					color: #5b9bd1;
					background-color: #f6f9fb;
					border-left: 2px solid #5b9bd1;
					margin-left: -2px;
					.mellegage & {
						color: $c-hsrmt-red;
						border-left-color: $c-hsrmt-red;
					}
				}
			}

			a {
				display: block;
				color: #93a3b5;
				font-size: 14px;
				font-weight: 400;
				padding-left: 5px;
				padding-top: 5px;
				padding-bottom: 5px;
				width: 100%;

				i {
					margin-right: 8px;
					font-size: 14px;
				}

				&:hover {
					background-color: #fafcfd;
					color: #5b9bd1;
					.mellegage & {
						color: $c-hsrmt-red;
					}
				}
			}
		}
	}
}