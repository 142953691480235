div {
	&.fx-input {
		position: relative;

		&.outline-slide {
			width: 6.5em;
			@import "inputs/outline_slide";
		}

		&.outline-in-to-out {
			width: 6.5em;
			@import "inputs/outline_in_to_out";
		}

		&.outline-trace {
			width: 6.5em;
			@import "inputs/outline_trace";
		}

		&.underline-ltr {
			width: 6.5em;
			@import "inputs/inline_ltr";
		}

		&.underline-in-to-out {
			width: 6.5em;
			@import "inputs/inline_in-to-out";
		}
	}
}