> div {
	width: 0.5em;
	height: 0.5em;
	background-color: #3cefff;
	border-radius: 50%;
	animation: fade 1.5s alternate ease-in-out infinite;

	&:nth-of-type(2),
	&:nth-of-type(4) {
		animation-delay: 0.25s;
	}

	&:nth-of-type(3),
	&:nth-of-type(5),
	&:nth-of-type(7) {
		animation-delay: 0.5s;
	}

	&:nth-of-type(6),
	&:nth-of-type(8) {
		animation-delay: 0.75s;
	}

	&:nth-of-type(9) {
		animation-delay: 1s;
	}
}