// global color definitions
// ---------------------------------------------------------------------------------------------------------------------
$c-blue: rgb(25, 117, 219);
$c-hsrmt: $c-blue;
$c-hsrmt-blue: $c-blue;
$c-hsrmt-red: rgb(239, 10, 5);

$c-grey: rgb(102, 102, 102);
$c-grey-lighter: lighten($c-grey, 50%);
$c-grey-light: lighten($c-grey, 20%);
$c-grey-dark: darken($c-grey, 20%);
$c-grey-darker: darken($c-grey, 50%);

$c-white: rgb(255, 255, 255);
$c-black: rgb(0, 0, 0);

$c-text-invert: $c-white;
$c-text: $c-grey-darker;

$c-red: rgba(216, 27, 27, 0.72);

$c-bg: $c-white;
$c-bg-grey: rgb(242, 242, 242);
$c-bg-grey-dark: $c-grey;

$c-error-red: rgba(216, 27, 27, 0.72);


$brand-colors: (
	homepage: $c-blue,
	forum: $c-blue,
	calendar: $c-blue,
	facebook: rgb(59, 89, 152),
	instagram: rgb(63, 114, 155),
	twitter: rgb(0, 172, 238),
	tumblr: rgb(52, 82, 111),
	discord: rgb(114, 137, 218)
);

// Shadows
// ---------------------------------------------------------------------------------------------------------------------

$sdw-transparence: 0.4;
$sdw-light: $c-white;
$sdw-dark: $c-black;

// Brands
// ---------------------------------------------------------------------------------------------------------------------
$hsrmt: homepage forum;
$brands: calendar facebook instagram twitter tumblr discord;

// Bootstraps Vars
// ---------------------------------------------------------------------------------------------------------------------

$custom-control-indicator-size: 1.2rem;