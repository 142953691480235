/* Carousel */
//.tales {
//	width: 100%;
//}
//.carousel-inner{
//	width:100%;
//	max-height: 200px !important;
//}


#teaser-carousel {
	.carousel-background {
		&.blur {
			filter: blur(8px);
			-webkit-filter: blur(8px);
		}

		&.fog {
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba($c-white, 0.4);

				.hsrmt_dark & {
					background-color: rgba($c-black, 0.4);
				}
			}
		}
	}

	.carousel-caption {
		&.center {
			position: absolute;
			top: 25%;
			left: 25%;
			right: 25%;
			bottom: auto;
			//transform: translateY(-50%);
			//background-color: rgba($c-black, 0.6);
			background-color: rgba($c-white, 0.9);
			color: $c-text;
		}
	}
}

/* List Groups */
.list-group.list-group-root {
	padding: 0;
	overflow: hidden;
}

.list-group.list-group-root .list-group {
	margin-bottom: 0;
}

.list-group.list-group-root .list-group-item {
	border-radius: 0;
	border-width: 1px 0 0 0;
}

.list-group.list-group-root > .list-group-item:first-child {
	border-top-width: 0;
}

.list-group.list-group-root > .list-group > .list-group-item {
	padding-left: 30px;
}

.list-group.list-group-root > .list-group > .list-group > .list-group-item {
	padding-left: 45px;
}

/* Accordion */
.accordion {
	[data-toggle] {
		position: relative;
		cursor: pointer;

		i, svg {
			position: absolute;
			right: 0;
		}

		&[aria-expanded="true"] {
			i, svg {
				transform: rotate(180deg);
			}
		}
	}
}


/* Nav-Tabs */
.nav-tabs {
	display:none;
}

@media(min-width:768px) {
	.nav-tabs {
		display: flex;
	}

	.card {
		border: none;
	}

	.card .card-header {
		display:none;
	}

	.card .collapse{
		display:block;
	}
}

@media(max-width:767px){
	.tab-pane {
		display: block !important;
		opacity: 1;
	}
}