.gallery {
    .gallery_item {
        position: relative;
        overflow: hidden;
        padding-bottom: 100%;

        @include media-breakpoint-up(sm) {
            padding-bottom: 50%;
        }

        @include media-breakpoint-up(md) {
            padding-bottom: 25%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-height: 100%;
            //min-width: 100%;
            max-height: 100%;
            transition: 0.2s ease-in-out;

            &:hover {
                zoom: 1.2;
                max-height: 120%;
                transition: 0.2s ease-in-out;
            }
        }
    }
}

.add-image-to-gallery {
}

.add-image-to-gallery-container {
}