/* Bubble Button - default params */
&.fx-bubble {
    z-index: 1;
    position: relative;
    font-size: 1.4rem;

    &.btn-sm {
        font-size: 1rem;
    }

    font-family: inherit;
    color: $c-text-invert !important;
    padding: 0.5em 1em;
    outline: none;
    border: none;
    background-color: $c-hsrmt-blue;
    overflow: hidden;
    transition: color 0.4s ease-in-out;

    .mellegage & {
        background-color: $c-grey;
    }

    &.small {
        font-size: 1rem;
    }

    &.outline {
        border: 1px solid rgba($c-text-invert, 0.2);
    }

    &::before {
        content: '';
        z-index: -1;
        position: absolute;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background-color: $c-hsrmt-red;
        transform-origin: center;
        transition: transform 0.45s ease-in-out;
    }

    &.active,
    &.current {
        background-color: $c-hsrmt-red;

        &::before {
            background-color: $c-hsrmt-blue;

            .mellegage & {
                background-color: $c-hsrmt-red;
            }
        }
    }

    &:hover {
        cursor: pointer;
        color: $c-text-invert;
    }

    /* Bubble Button - left bottom to right top */
    &.lb-t-rt {
        &::before {
            top: 100%;
            right: 100%;
            transform: translate3d(50%, -50%, 0) scale3d(0, 0, 0);
        }

        &:hover {
            &::before {
                transform: translate3d(50%, -50%, 0) scale3d(15, 15, 15);
            }
        }
    }

    /* Bubble Button - left top to right bottom */
    &.lt-t-rb {
        &::before {
            bottom: 100%;
            right: 100%;
            transform: translate3d(50%, 50%, 0) scale3d(0, 0, 0);
        }

        &:hover {
            &::before {
                transform: translate3d(50%, 50%, 0) scale3d(15, 15, 15);
            }
        }
    }

    /* Bubble Button - right top to left bottom */
    &.rt-t-lb {
        &::before {
            bottom: 100%;
            left: 100%;
            transform: translate3d(-50%, 50%, 0) scale3d(0, 0, 0);
        }

        &:hover {
            &::before {
                transform: translate3d(-50%, 50%, 0) scale3d(15, 15, 15);
            }
        }
    }

    /* Bubble Button - right bottom to left top */
    &.rb-t-lt {
        &::before {
            top: 100%;
            left: 100%;
            transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
        }

        &:hover {
            &::before {
                transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
            }
        }
    }

    /* Bubble Button - in to out */
    &.in-to-out {
        &::before {
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
        }

        &:hover {
            &::before {
                transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
            }
        }
    }

    &.danger {
        background-color: $c-hsrmt-red;
        &::before {
            background-color: $c-hsrmt-blue;
        }

        &.active,
        &.current {
            background-color: $c-hsrmt-blue;

            &::before {
                background-color: $c-hsrmt-red;

                .mellegage & {
                    background-color: $c-hsrmt-blue;
                }
            }
        }
    }
}