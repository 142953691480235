&.circle-rotate {
	perspective: 120px;

	.coin {
		width: 2em;
		height: 2em;
		border-radius: 50%;
		border: 4px solid #3cefff;
		animation: spin-x 1.5s ease-in-out infinite;
	}
}