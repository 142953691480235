&.box-fill-rotate {
	position: relative;
	width: 2em;
	height: 2em;
	border: 3px solid #3cefff;
	overflow: hidden;
	animation: rotate 3s ease infinite;

	&::before {
		content: '';
		position: absolute;
		top: -3px;
		left: -3px;
		width: 2em;
		height: 2em;
		background-color: hsla(185, 100%, 62%, 0.75);
		transform-origin: center bottom;
		transform: scaleY(1);
		animation: fill 3s linear infinite;
	}
}