body, html {
    position: relative;
    font-size: 10px;
    min-height: 100vh;

    .content-wrap {
        padding-bottom: 15rem; /* Footer height */
    }

    .background {
        background-color: rgba($c-bg, 0.9);

        .hsrmt_dark & {
            background-color: rgba($c-black, 0.4);
        }
    }

    .grecaptcha-badge {
        z-index: 99999;
    }

    &#landingpage {
        #loader {
            display: none;
        }
    }

    &.mellegage {
        a {
            color: $c-hsrmt-red;
        }
    }

    &#about-us {
        #nextEvent {
            font-family: 'Shojumaru', cursive;
            position: fixed;
            top: 15rem;
            left: 5rem;
            z-index: 999999;
            transform: rotate(-25deg);

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &#about-us,
    &#login,
    &#thankyou,
    &#rules,
    &#imprint,
    &#bugreport,
    &#privacy-police,
    &#registration-form,
    &#member,
    &#member-detail,
    &#gallery,
    &#gallery-detail,
    &.dashboard,
    &#chatrooms,
    &#become-a-member {
        #teaser-carousel {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        .content-wrap {
            position: relative;
            padding-top: 100vh;
            z-index: 100;
        }
    }

    &#bugreport,
    &#rules,
    &#imprint,
    &#chatrooms,
    &#thankyou,
    &#registration-form,
    &#privacy-police {
        .content-wrap {
            padding-top: 20vh;
        }
    }

    &#about-us {
        .content-wrap {
            padding-top: 100vh;
        }
    }

    &#login,
    &#member,
    &#member-detail,
    &#gallery,
    &#gallery-detail,
    &.dashboard,
    &#become-a-member {
        .content-wrap {
            padding-top: 15rem;
        }
    }

    > * {
        font-size: 1.4rem;
    }

    .version {
        font-size: 1rem;
        position: fixed;
        bottom: 0;
        left: 0;
        color: $c-grey;
        z-index: 999;
    }
}

h1, h2, h3, h4, h5 {
    &:first-letter {
        color: $c-hsrmt-red;
    }
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 12.5rem;
    z-index: 10;

    #login &,
    #thankyou &,
    .dashboard &,
    #bugreport &,
    #become-a-member & {
        position: fixed;
    }
}

#landingpage p,
h1, h2, h3, h4, h5, h6 {
    font-family: 'Shojumaru', cursive !important;

    span {
        &.hl-red {
            color: $c-hsrmt-red;
        }

        &.hl-blue {
            color: $c-hsrmt-blue;
        }
    }
}

.btn-hsrmt-simple {
    background: $c-hsrmt-blue;

    .mellegage & {
        background: $c-hsrmt-red;
    }
}

.btn-hsrmt {
    @include button-variant($c-hsrmt-blue, darken($c-hsrmt-blue, 7.5%), darken($c-hsrmt-red, 10%), lighten($c-hsrmt-red, 5%), lighten($c-hsrmt-red, 10%), darken($c-hsrmt-red, 30%));

    .mellegage & {
        @include button-variant($c-grey, darken($c-grey, 7.5%), darken($c-hsrmt-red, 10%), lighten($c-hsrmt-red, 5%), lighten($c-hsrmt-red, 10%), darken($c-hsrmt-red, 30%));
    }
}

.btn-outline-hsrmt {
    @include button-outline-variant($c-hsrmt-blue, #222222, lighten($c-hsrmt-red, 5%), $c-hsrmt-red);

    .mellegage & {
        @include button-outline-variant($c-grey, #222222, lighten($c-hsrmt-red, 5%), $c-hsrmt-red);
    }
}

@each $hsrmt in $hsrmt {
    @include getBrandColor($hsrmt);
}

@each $brand in $brands {
    @include getBrandColor($brand, 10rem);
}

#loader {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($c-white, 0.8);

    .fx-loader-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    &.fadeout {
        animation: fade-out 1s 1;
        -webkit-animation: fade-out 1s 1;
        animation-fill-mode: forwards;
    }
}

blockquote {
    background-color: $c-grey-lighter;
    border-width: 0.1rem 0 0.1rem 0.1rem;
    border-style: solid;
    border-color: $c-grey;
    padding-left: 1rem;
}

footer {
    background-color: $c-grey-dark;
    color: $c-white;
    font-size: 1.6rem;
}

footer .btn {
    color: $c-white;
    font-size: 2.2rem;

    &:hover {
        color: $c-hsrmt;
    }
}

ul {
    &.paragraph {
        list-style-type: none;
    }
}

.shadow {
    &.light {
        //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        //-webkit-box-shadow: 10px 10px 20px rgba($sdw-light, $sdw-transparence) !important;
        //-moz-box-shadow: 10px 10px 20px rgba($sdw-light, $sdw-transparence) !important;
        //box-shadow: 10px 10px 20px rgba($sdw-light, $sdw-transparence) !important;
        -webkit-box-shadow: 0 4px 8px 0 rgba($sdw-light, $sdw-transparence), 0 6px 20px 0 rgba($sdw-light, $sdw-transparence) !important;
        -moz-box-shadow: 0 4px 8px 0 rgba($sdw-light, $sdw-transparence), 0 6px 20px 0 rgba($sdw-light, $sdw-transparence) !important;
        box-shadow: 0 4px 8px 0 rgba($sdw-light, $sdw-transparence), 0 6px 20px 0 rgba($sdw-light, $sdw-transparence) !important;
    }

    &.dark {
        //-webkit-box-shadow: 10px 10px 20px rgba($sdw-dark, $sdw-transparence) !important;
        //-moz-box-shadow: 10px 10px 20px rgba($sdw-dark, $sdw-transparence) !important;
        //box-shadow: 10px 10px 20px rgba($sdw-dark, $sdw-transparence) !important;
        -webkit-box-shadow: 0 4px 8px 0 rgba($sdw-dark, $sdw-transparence), 0 6px 20px 0 rgba($sdw-dark, $sdw-transparence) !important;
        -moz-box-shadow: 0 4px 8px 0 rgba($sdw-dark, $sdw-transparence), 0 6px 20px 0 rgba($sdw-dark, $sdw-transparence) !important;
        box-shadow: 0 4px 8px 0 rgba($sdw-dark, $sdw-transparence), 0 6px 20px 0 rgba($sdw-dark, $sdw-transparence) !important;
    }

    &-light {
        &.light {
            //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            //-webkit-box-shadow: 10px 10px 20px rgba($sdw-light, $sdw-transparence) !important;
            //-moz-box-shadow: 10px 10px 20px rgba($sdw-light, $sdw-transparence) !important;
            //box-shadow: 10px 10px 20px rgba($sdw-light, $sdw-transparence) !important;
            -webkit-box-shadow: 1px 2px 10px rgba($sdw-light, $sdw-transparence) !important;
            -moz-box-shadow: 1px 2px 10px rgba($sdw-light, $sdw-transparence) !important;
            box-shadow: 1px 2px 10px rgba($sdw-light, $sdw-transparence) !important;
        }

        &.dark {
            //-webkit-box-shadow: 10px 10px 20px rgba($sdw-dark, $sdw-transparence) !important;
            //-moz-box-shadow: 10px 10px 20px rgba($sdw-dark, $sdw-transparence) !important;
            //box-shadow: 10px 10px 20px rgba($sdw-dark, $sdw-transparence) !important;
            -webkit-box-shadow: 1px 2px 10px rgba($sdw-dark, $sdw-transparence) !important;
            -moz-box-shadow: 1px 2px 10px rgba($sdw-dark, $sdw-transparence) !important;
            box-shadow: 1px 2px 10px rgba($sdw-dark, $sdw-transparence) !important;
        }
    }
}

.border-gradient {
    position: relative;

    &.top {
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            top: 0;
            left: 0;
            background: linear-gradient(45deg, $c-hsrmt-red, $c-hsrmt-blue);
        }
    }

    &.bottom {
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            bottom: 0;
            left: 0;
            background: linear-gradient(45deg, $c-hsrmt-red, $c-hsrmt-blue);
        }
    }
}

.scroll-down {
    color: $c-white;
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    cursor: pointer;

    svg {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 5rem;
        animation: scroll-down 3s ease-out 4s 3;
    }

    &:hover {
        svg {
            animation: scroll-down 3s ease-out 0s infinite;
        }
    }
}

/* Login */
#login {
    .container {
        .card {
            width: 40rem;

            img {
                max-width: 100px;
                width: 100%;
                height: auto;
            }
        }
    }
}

@import "styles/sidepanel";

.numberplate {
    border: 1px solid $c-black;
    position: relative;
    height: 30px;
    padding-left: 25px;
    padding-right: 5px;
    border-radius: 5px;

    > div {
        display: inline;
        font-size: 1.8rem;

        &:first-child {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 20px;
            text-align: center;
            color: $c-white;
            background: $c-blue;
        }
    }

    input {
        display: inline;
        width: 30%;
    }
}
