input {
	width: 100%;
	color: white;
	font-size: inherit;
	font-family: inherit;
	background-color: transparent;
	border: 1px solid transparent;
	border-bottom-color: hsla(341, 97%, 59%, 0.2);

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: hsla(0, 0%, 100%, 0.6);
	}

	&:focus ~ span {
		transform: translate(-50%, 0) scaleX(1);
		opacity: 1;
	}
}

span {
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 100%;
	height: 1px;
	opacity: 0;
	background-color: #fc2f70;
	transform-origin: center;
	transform: translate(-50%, 0) scaleX(0);
	transition: all 0.3s ease;
}
