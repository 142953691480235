@mixin getBrandColor($brand, $fontsize: false, $font: false) {
	.button-#{$brand} {
		@if $font {
			color: map-get($brand-colors, $brand);
		} @else {
			background-color: map-get($brand-colors, $brand);
			color: #FFFFFF;
		}
		@if $fontsize {
			font-size: $fontsize;
		}
	}
}