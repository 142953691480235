.highlight {
	position: relative;
	z-index: 1;

	&.l-t-r {
		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			bottom: 0;
			left: -0.25em;
			right: -0.25em;
			//background-color: hsla(341, 97%, 59%, 0.75);
			background-color: $c-hsrmt-blue;
			.mellegage & {
				background-color: $c-hsrmt-red;
			}
			transform-origin: center right;
			transform: scaleX(0);
			transition: transform 0.2s ease-in-out;
		}

		&:hover {
			color: $c-text-invert;
			&::before {
				transform: scaleX(1);
				transform-origin: center left;
			}
		}
	}
}