svg {
	width: 3.75em;
	transform-origin: center;
	animation: rotateFull 2s linear infinite;
}

circle {
	fill: none;
	stroke: $c-hsrmt-blue;
	.mellegage & {
		stroke: $c-hsrmt-red;
	}
	stroke-width: 2;
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	stroke-linecap: round;
	animation: dash 1.5s ease-in-out infinite;
}