@keyframes jelly {
	0%,
	100% {
		transform: scale(1, 1);
	}
	25% {
		transform: scale(0.9, 1.1);
	}
	50% {
		transform: scale(1.1, 0.9);
	}
	75% {
		transform: scale(0.95, 1.05);
	}
}


@keyframes grow {
	0%,
	100% {
		transform: scaleY(1);
	}

	50% {
		transform: scaleY(2);
	}
}


@keyframes rotate {
	50%,
	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotateFull {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dashoffset: -125px;
	}
}


@keyframes fill {
	25%,
	50% {
		transform: scaleY(0);
	}
	100% {
		transform: scaleY(1);
	}
}

@keyframes spin-circle {
	to {
		transform: rotate(360deg);
	}
}

@keyframes spin-x {
	to {
		transform: rotateY(540deg);
	}
}

@keyframes left-swing {
	50%,
	100% {
		transform: translateX(95%);
	}
}

@keyframes right-swing {
	50% {
		transform: translateX(-95%);
	}
	100% {
		transform: translateX(100%);
	}
}

@keyframes spin-stretch {
	50% {
		transform: rotate(360deg) scale(0.4, 0.33);
		border-width: 8px;
	}
	100% {
		transform: rotate(720deg) scale(1, 1);
		border-width: 3px;
	}
}


@keyframes fade {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes flip {
	50% {
		transform: rotateY(180deg);
	}
	100% {
		transform: rotateY(180deg) rotateX(180deg);
	}
}

@keyframes fade {
	to {
		opacity: 0.2;
	}
}


@keyframes multiline-spin {
	to {
		transform: rotate(360deg);
	}
}

@keyframes pulse {
	from {
		transform: scale(0.5);
	}
	to {
		transform: scale(1);
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}
	20% {
		opacity: 1;
	}
	70% {
		opacity: 1;
		-webkit-transform: translateY(0px);
	}
	99% {
		opacity: 0;
		-webkit-transform: translateY(0px);
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
	}
}

@keyframes scroll-down {
	0% {
		top: 20px;
	}
	30% {
		top: 30px;
	}
	31% {
		top: 20px;
	}
	60% {
		top: 30px;
	}
	61% {
		top: 20px;
	}
}

@keyframes animate-navbar {
	0% {
		height: 105px;
		background-color: rgba($c-white, 0);

		.navbar-brand {
			img {
				height: 100px;
				width: auto;
			}
		}

		.navbar-nav {
			.nav-link {
				font-size: 1.6rem;
			}
		}
	}
	100% {
		height: 60px;
		background-color: rgba($c-white, 0.8);

		.navbar-brand {
			img {
				height: 50px;
				width: auto;
			}
		}

		.navbar-nav {
			.nav-link {
				font-size: 1.2rem;
			}
		}
	}
}
