input {
	width: 100%;
	color: white;
	font-size: inherit;
	font-family: inherit;
	background-color: transparent;
	border: 1px solid transparent;
	border-bottom-color: hsla(185, 100%, 62%, 0.2);

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: hsla(0, 0%, 100%, 0.6);
	}

	&:focus ~ span {
		transform-origin: bottom left;
		transform: scaleX(1);
	}
}

span {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 1px;
	background-color: #3cefff;
	transform-origin: bottom right;
	transform: scaleX(0);
	transition: transform 0.5s ease;
}
