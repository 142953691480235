#bugreport-popup {
	position: fixed;
	top: 20%;
	right: 0;
	z-index: 9999;

	.opener {
		font-size: 2rem;
		position: absolute;
		top: 0;
		left: -40px;
		width: 40px;
		height: 40px;
		border-radius: 20px;
	}
}

.opener {
	font-size: 2rem;
	width: 4rem;
	height: 4rem;
	border-radius: 20px;
	.navbar.small & {
		width: 3rem;
		height: 3rem;
	}
}